import '../assets/scss/loading.scss'

const LoadingImage = require('../assets/images/skejuul-loader.gif')


export default function LoadingCom(props) {
    return (
        <div className="LoadingContainer">
            <img width="100px" alt="" src={LoadingImage.default} />
            {/* <svg> */}
            {/* <circle fill="none" stroke="#fff" stroke-width="4" stroke-miterlimit="10" cx="50" cy="50" r="48"/> */}
            {/* <line fill="none" strokeLinecap="round" stroke="#297fb8" strokeWidth="4" strokeMiterlimit="10" x1="50" y1="50" x2="85" y2="50.5">
            <animateTransform 
                attributeName="transform" 
                dur="2s"
                type="rotate"
                from="0 50 50"
                to="360 50 50"
                repeatCount="indefinite" />
            </line>
            <line fill="none" strokeLinecap="round" stroke="#297fb8" strokeWidth="4" strokeMiterlimit="10" x1="50" y1="50" x2="49.5" y2="74">
            <animateTransform 
                attributeName="transform" 
                dur="15s"
                type="rotate"
                from="0 50 50"
                to="360 50 50"
                repeatCount="indefinite" />
            </line>
            </svg> */}
            <p>{props.children}</p>
        </div>
    )
}



