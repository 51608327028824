import React, { useState, useEffect } from "react";
import {Link} from 'react-router-dom'
import auth from "./auth";
import { TextField, Button, Snackbar, Alert  } from "@mui/material";
import axios from "axios";
import ReCAPTCHA from "react-google-recaptcha";
import '../assets/scss/landingPage.scss'

import { useFormik } from "formik";
import * as Yup from 'yup';

import { environment } from './env'
import LoadingCom from "./Loading";
import Branding from "./Branding";
import DownloadApp from "./DownloadApp";

import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';


export const LandingPage = props => {

    const [serverError, setServerError] = useState ({error:false, msg:''})
    const [capcha, setCapcha] = useState ({showCaptcha: false, status: 0, captcha : ''})
    const [Loading, setLoading] = useState (true)
    const [snackbarClose, setSnackbarClose] = useState(true)
    const [noNetwork, setNoNetwork] = useState(false)
    const [showApp, setShowApp] = useState (true) // making download app banner hidden later we can make it dynamic
    const [showPassword, setshowPassword] = useState(false)
    
    const handleSnackClose = () => {
      setSnackbarClose(true)
    }

    const formik = useFormik({
        initialValues:{ validateOnMount: true, email:'', password:'', captcha : '',
        "appID" : "1",
        firebaseID : ""},
        validationSchema:Yup.object({
            email: Yup
            .string()
            .required('Email is required')
            .email('Email format is not correct'),
            password: Yup
            .string()
            .required('Password is required')
        }),
        onSubmit: values => {
          setLoading (false)
          setNoNetwork(false)
          setServerError({error:false, msg:''})
          auth.login((reqdata) => {
                axios.post(`${environment.apiUrl}/login`, {
                    "data" : {
                        "email" : values.email,
                        "pwd" : values.password,
                        "captcha" : capcha.captcha,
                        "appID" : environment.appID,
                        firebaseID : ""
                    }
                })
                .then((response) => {
                    const saveData = JSON.stringify( response.data)
                   // console.log(saveData)
                    if(response.data.showCaptcha === true){
                      setCapcha({
                        showCaptcha: true,
                        status: 0,
                        captcha : ''
                      })
                      window.location.reload(true);
                    //  console.log(response.data.errorMessage !== "");
                    }
                    if(response.data.errorMessage !== ""){
                      setServerError({
                        error: true,
                        msg: response.data.errorMessage
                      })
                    //  console.log(response.data.errorMessage !== "");
                    }
                    if(response.data.status === 0){
                        //console.log(response.data);
                        localStorage.setItem("currentUser", saveData)
                        var userdetails = JSON.parse(saveData)
                        //console.log(userdetails.role)
                        if(userdetails.role === 'Staff'){
                          props.history.push("/home");
                        }else if(userdetails.role === "Facility Administrator"){
                          props.history.push("/homeFacility");
                        }else{
                          props.history.push("/home");
                        }
                        
                    }
                  }, (error) => {
                    console.log(error);
                    setLoading (false)
                    setNoNetwork(true)
                  });
                
            });
        }
    })

    useEffect(() => {
      setLoading (
        prevCheck => !prevCheck
      )
      setTimeout(function () {
        setShowApp(false);
      }, 10000);
      
      axios.post(`${environment.apiUrl}/captchaStatus`, {
        "data" : {
            "appID" : environment.appID
        }
      })
      .then((response) => {
        if(response.data.showCaptcha === true){
          setCapcha({
            showCaptcha: true,
            status: 0,
            captcha : ''
          })
          
        }
      }, (error) => {
        console.log(error);
        setNoNetwork(true)
      });
    }, [])

    useEffect(() => {
        const getDetails = JSON.parse(localStorage.getItem("currentUser"));
        if(getDetails !== null && getDetails.status === 0 && getDetails.role === 'Staff'){
            auth.login(() => {
                props.history.push("/home");
              });
        }
        if(getDetails !== null && getDetails.status === 0 && getDetails.role=== "Facility Administrator"){
            auth.login(() => {
                props.history.push("/homeFacility");
              });
        }
        if(props.location.flag === true){
          
          setSnackbarClose(false)
        }
    },[]);
    
    const onChangeRecapcha = (captchaResponse) => {
      setCapcha({
        showCaptcha: true,
        status: 0,
        captcha : captchaResponse
      })
    }

return (
    <>
    
    {Loading ? 
      <LoadingCom /> : 
      <div id="login">
        <Branding styles="logo" />
        
        <form  onSubmit={formik.handleSubmit}>
          <ul>
            <li>
                <TextField
                  id="email" 
                  name="email" 
                  error={formik.errors.email && formik.touched.email ? true : false}
                  fullWidth 
                  value={formik.values.email}
                  onChange={formik.handleChange}  
                  onBlur={formik.handleBlur}
                  label="Email" variant="standard" 
                  helperText={formik.errors.email}
                /> 
            </li>
            <li className="passwordfiled">
                <TextField 
                  id="password" 
                  name="password" 
                  fullWidth 
                  error={formik.errors.password && formik.touched.password ? true : false}
                  value={formik.values.password}
                  onChange={formik.handleChange}  
                  onBlur={formik.handleBlur} 
                  label="Password" variant="standard" type={!showPassword ? "password" : "text"}
                  helperText={formik.errors.password}
                />
                {!showPassword ? 
                <Button className="showhide" color="primary" onClick={() => { setshowPassword(true) }}><VisibilityOutlinedIcon color="primary" fontSize="small" /></Button>
                : <Button className="showhide" color="primary" onClick={() => { setshowPassword(false) }}><VisibilityOffOutlinedIcon color="primary" fontSize="small"/></Button>
                }
            </li>
            {/*  Capcha Condirional render Start */}
                {capcha.showCaptcha ? 
                <li>
                <ReCAPTCHA
                sitekey={environment.public_key}
                onChange={onChangeRecapcha}
                /> 
                </li> : null}
            {/*  Capcha Condirional render End */}

            {/* Server error conditional render start*/}
            {serverError.error ? 
            <li className="server-error">
              <Alert severity="error">{serverError.msg}</Alert>
            </li> : null}
            {noNetwork?
              <li className="server-error">
              <Alert severity="error">Not connected to internet</Alert>
              </li>
            : null}
            {/* Server error conditional render end */}

            <li><Button variant="contained" type="submit" className="hwl-button-blue" disabled={!(formik.isValid && formik.dirty)} fullWidth>Login</Button></li>
          </ul>
        </form>
        <Link className="forgot-password" to="/forgotpassword">Create/Forgot Password</Link>
      </div>
      }
      

      {snackbarClose ?
        null
        :<Snackbar open={true} autoHideDuration={6000} onClose={handleSnackClose} >
        <Alert severity="success" sx={{ width: '100%' }}>
        {props.location.state}
        </Alert>
        </Snackbar>
      }
      {showApp ? <DownloadApp /> : null }
    </>
  );
};
