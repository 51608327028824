import React from 'react';
import {Link} from 'react-router-dom'
import {
  isAndroid,
  isIOS
} from "react-device-detect";
import '../assets/scss/DownloadApp.scss'

const Icon = require('../assets/images/skejuulme.png')
export default function DownloadApp() {
    

      
        return (
            <>
            {isAndroid || isIOS ?
            <div className='appdownload blink_me'>
                <img width="85px" alt="" src={Icon.default} />
                <div className='appcontent'>
                    <h3>skejuul.me</h3>
                    <h4>HWLWorks Shift Planner</h4>
                    {/* <p>GET - On the {isAndroid ? "Play" : null}{isIOS ? "App" : null} Store</p>*/}
                    <p>{isAndroid ? <Link to={{pathname:"https://play.google.com/store/apps/details?id=com.shiftrock.mobile.hwl"}} target="_blank" >Get App</Link> : null}</p>

                    <p>{isIOS ? <Link  to={{pathname:"https://apps.apple.com/us/app/hwl-shift-planner/id1475216177?ls=1"}}
                    target="_blank" >Get App</Link> : null}</p>
                                  
                </div>
                {/* <div className='linkapp'>
                    {isAndroid ? <Link to={{pathname:"https://play.google.com/store/apps/details?id=com.shiftrock.mobile.hwl"}} target="_blank" >View</Link> : null}

                    {isIOS ? <Link  to={{pathname:"https://apps.apple.com/us/app/hwl-shift-planner/id1475216177?ls=1"}}
                    target="_blank" >View</Link> : null}
                </div> */}
            </div>
            :null }
            </>
        )
}
