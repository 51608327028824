import React from 'react'
const BrandImage = require('../assets/images/skejuul.png')

export default function Branding(props) {
    return (
        <section className={props.styles}>
          <img width="190px" alt="" src={BrandImage.default} />
          <p>Powered by HWL</p>
        </section>
    )
}
