class Auth {
    constructor() {
      this.authenticated = false;
    }

    componentDidMount(){
      this.setState( { authenticated: JSON.parse(localStorage.getItem("auth")) } )
    }
  
    login(cb) {
      localStorage.setItem("auth","true")
      this.authenticated = true;
      cb();
    }
  
    logout = async (cb) => {
        await  
        localStorage.removeItem("auth")
        localStorage.removeItem("load")
        localStorage.removeItem('buttonName');
        localStorage.removeItem("currentUser");
        localStorage.removeItem("testObject");
        localStorage.removeItem("lastData");
        localStorage.removeItem("format");
        this.authenticated = false;

      cb();
    }
  
    isAuthenticated() {
      if (localStorage.getItem("auth") === null || localStorage.getItem("currentUser") === null) {
        this.authenticated = false;
      } else {
        return this.authenticated = true;
      }
     
    }
  }
  
  export default new Auth();