import React from "react";
import { LandingPage } from "./component/landingPage";

import { ProtectedRoute } from "./component/protectedRoute";
import { BrowserRouter, Route, Switch } from "react-router-dom";
const Home = React.lazy(()=> import('./component/Pages/Home')) 
const HomeFacility = React.lazy(()=> import( "./component/Pages/HomeFacility"))
const Settings = React.lazy(()=> import( "./component/Pages/Settings"))
const AdhoctimeCard = React.lazy(()=> import( "./component/Pages/AdhoctimeCard"))
const Changepassword = React.lazy(()=> import( "./component/Pages/Changepassword"))
const CopyrightNotice = React.lazy(()=> import( "./component/Pages/CopyrightNotice"))
const Calendar = React.lazy(()=> import( "./component/Pages/Calendar"))
const ForgotPassword = React.lazy(()=> import( "./component/Pages/ForgotPassword"))
const TimecardSubmit = React.lazy(()=> import( "./component/Pages/TimecardSubmit"))
const Availability = React.lazy(()=> import( "./component/Pages/Availability"))
const MultidayAvailability = React.lazy(()=> import( "./component/Pages/Availability/multidayavailability"))
const RecurringAvailability = React.lazy(()=> import( "./component/Pages/Availability/recurringrvailability"))
const RemoveAvailability = React.lazy(()=> import( "./component/Pages/Availability/removeavailability"))
const notfound = React.lazy(()=> import( "./component/Pages/notfound"))

// const strictmode = JSON.parse(localStorage.getItem("currentUser")).staffType

export default function App() {
  return (
    <BrowserRouter>
    <React.Suspense fallback={<p>Loading</p>}>
    <div className="App">
      <Switch>
        <Route exact path="/" component={LandingPage} />
        <ProtectedRoute exact path="/home" component={Home} />
        <ProtectedRoute exact path="/homeFacility" component={HomeFacility} />
        <Route exact path="/forgotpassword" component={ForgotPassword} />
        <ProtectedRoute exact path="/settings" component={Settings}/>
        <ProtectedRoute exact path="/calendar" component={Calendar}/>
        <ProtectedRoute exact path="/adhoctimeCard" component={AdhoctimeCard}/>
        <ProtectedRoute exact path="/availability" component={Availability}/>
        <ProtectedRoute exact path="/multidayavailability" component={MultidayAvailability}/>
        <ProtectedRoute exact path="/recurringrvailability" component={RecurringAvailability}/>
        <ProtectedRoute exact path="/removeavailability" component={RemoveAvailability}/>
        <ProtectedRoute exact path="/changepassword" component={Changepassword}/>
        <ProtectedRoute exact path="/copyrightnotice" component={CopyrightNotice}/>
        <ProtectedRoute exact path="/timecardSubmit/:flag/:id/" component={TimecardSubmit}/>
        <Route path="*" component={notfound} />
        
      </Switch>
    </div>
    </React.Suspense>
    </BrowserRouter>
  );
}


